<template>
  <div class="changePW">
    <div class="Login changePW-box">
      <div class="mainBox">
        <h1>
          <router-link to="/">
            <img src="@/assets/images/reviewmind/logo2.png" alt="로고" />
          </router-link>
        </h1>
        <h2>{{ $t("change_password") }}</h2>

        <div class="form login">
          <form>
            <fieldset>
              <div class="cover bg" v-if="isLogin">
                <input
                  v-model="password"
                  type="password"
                  placeholder="현재 비밀번호 입력"
                />
              </div>
              <div class="cover bg">
                <input
                  v-model="newPwd"
                  type="password"
                  placeholder="신규 비밀번호 입력"
                />
                <input
                  v-model="newPwdConfirm"
                  type="password"
                  placeholder="비밀번호 확인"
                />
              </div>
              <button type="button" class="btn full primary" @click="submit">
                비밀번호 변경
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sha256 from "js-sha256";
import { changePassword, vchangePassword } from "@/api/member";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";

export default {
  mixins: [format, setNavbar, setFooter],
  data() {
    return {
      newPwd: "",
      newPwdConfirm: "",
      password: "",
      show: true,
      errMsg: false,
    };
  },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  methods: {
    submit() {
      if (this.newPwd == "") {
        return alert("신규 비밀번호를 입력해 주세요.");
      }
      if (this.newPwdConfirm == "") {
        return alert("비밀번호 확인을 입력해 주세요.");
      }
      if (this.newPwd != this.newPwdConfirm) {
        return alert("신규 비밀번호를 확인해 주세요.");
      }
      let data = {
        newPwd: "",
        password: "",
      };
      data.newPwd = sha256(this.newPwd);

      if (this.isLogin) {
        if (this.password == "") return alert("현재 비밀번호를 입력해 주세요.");
        data.password = sha256(this.password);

        changePassword(data).then((res) => {
          if (res.data.status === 200) {
            let message = res.data.message;
            alert(message);
            this.$router.push({ name: "home" });
            // this.$router.push("/joinDone");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        data.email = this.$route.params.email;
        data.mobileNo = this.$route.params.mobileNo;

        vchangePassword(data).then((res) => {
          if (res.data.status === 200) {
            let message = res.data.message;
            alert(message);
            this.$router.push({ name: "home" });
            // this.$router.push("/joinDone");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
