export const setFooter = {
  methods: {
    setFooter(visible) {
      let data = {
        visible: visible
      };
      this.$store.dispatch("SET_FOOTER", data);
    }
  }
};
